export const environment = {
  production: true,
  api: 'https://api.parlon.ph/api/',
  booking: 'https://api.parlon.ph/site/booking/',
  deals:'prod_Deals',
  parlons:'prod_Parlon',
  deals_price_desc:'prod_Deals_price_desc',
  deals_price_asc:'prod_Deals_price_asc',
  og_offers:'prod_OG_Offers',
  og_brands:'prod_OG_Brands',
  resource: "https://assets.parlon.ph/",
  imagekit: "https://ik.imagekit.io/parlon/",
  currency: "PHP",
  currencySign: "₱",
  showLocation: true,
  emptyNav: false,
  teaser: false,
  hide_user_success_message:false,
  country:'Philippines',
  typesense_url:'https://rk27dfaiw9n3t6svp-1.a1.typesense.net/',
  typesense_key:'wkBdaHgfGJRTHwlMWfQIPl5ru0eZ1fJD',
  maya_token: 'cGstYkM4MklMOFl6aGtTdGRVZnNkOUkxS01MZEJhWXFoS0VRZ3E3d0g4U1BUcTo=',
  maya_url:'https://pg.paymaya.com/payments/v1/payment-tokens'
};
